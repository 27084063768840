const ROUTES = {
  MAIN: '/',
  HOME: '/',
  LOGIN: '/login',
  SHIRTS: { main: '/shirt', view: '/shirt/:id' },
  QUIZ: '/quiz',
  QUIZ_PLAY: '/quiz/play',
  QUIZ_LEADERBOARD: '/quiz/leaderboard',
};

export { ROUTES };
