import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ModalityState {
  modality: number;
  loading: boolean;
  error: string | null;
}

const initialState: ModalityState = {
  modality: 0,
  loading: false,
  error: null,
};

export const modalitySlice = createSlice({
  name: 'modality',
  initialState,
  reducers: {
    setModality: (state, action: PayloadAction<ModalityState['modality']>) => {
	state.modality = action.payload;
	state.loading = false;
	state.error = null;
	}
  },
});

export const { setModality } = modalitySlice.actions;

export default modalitySlice.reducer;
