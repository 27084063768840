import { useState } from "react";
import { Button, Typography } from "@/core/ui";

interface VirtualKeyboardProps {
  setPlayerName: React.Dispatch<React.SetStateAction<string>>;
}

const VirtualKeyboard = ({ setPlayerName }: VirtualKeyboardProps) => {
  const [isNumberKeyboard, setIsNumberKeyboard] = useState(false);

  const handleKeyClick = (key: string) => {
    setPlayerName((prevName: string) => prevName + key);
  };

  const handleBackspace = () => {
    setPlayerName((prevName: string) => prevName.slice(0, -1));
  };

  const toggleKeyboardMode = () => {
    setIsNumberKeyboard((prevMode) => !prevMode);
  };

  const letters = "QWERTYUIOPASDFGHJKLÇZXCVBNM".split("");
  const numbers = "1234567890".split("");

  return (
    <div className="mt-8 grid grid-cols-10 gap-4 px-8">
      {/* Row 1 */}
      {(isNumberKeyboard ? numbers : letters.slice(0, 10)).map((key) => (
        <Button
          key={key}
          className="text-[40px] p-6 bg-gray-800 rounded"
          onClick={() => handleKeyClick(key)}
        >
          <Typography className="text-white">{key}</Typography>
        </Button>
      ))}

      {/* Row 2 */}
      {(isNumberKeyboard ? [] : letters.slice(10, 19)).map((key) => (
        <Button
          key={key}
          className="text-[40px] p-6 bg-gray-800 rounded col-span-1"
          onClick={() => handleKeyClick(key)}
        >
          <Typography className="text-white">{key}</Typography>
        </Button>
      ))}

      {/* Row 3 */}
      {(isNumberKeyboard ? [] : letters.slice(19)).map((key) => (
        <Button
          key={key}
          className="text-[40px] p-6 bg-gray-800 rounded col-span-1"
          onClick={() => handleKeyClick(key)}
        >
          <Typography className="text-white">{key}</Typography>
        </Button>
      ))}

      {/* Last Row: "123" or "ABC", ",", "Space", ".", "Backspace" */}
      <Button
        className="text-[40px] p-6 bg-gray-800 rounded col-span-2"
        onClick={toggleKeyboardMode}
      >
        <Typography className="text-white">
          {isNumberKeyboard ? "ABC" : "123"}
        </Typography>
      </Button>

      <Button
        className="text-[40px] p-6 bg-gray-800 rounded col-span-1"
        onClick={() => handleKeyClick(",")}
      >
        <Typography className="text-white">,</Typography>
      </Button>

      <Button
        className="text-[40px] p-6 bg-gray-800 rounded col-span-5"
        onClick={() => handleKeyClick(" ")}
      >
        <Typography className="text-white">Space</Typography>
      </Button>

      <Button
        className="text-[40px] p-6 bg-gray-800 rounded col-span-1"
        onClick={() => handleKeyClick(".")}
      >
        <Typography className="text-white">.</Typography>
      </Button>

      <Button
        className="text-[40px] p-6 bg-gray-800 rounded col-span-1"
        onClick={handleBackspace}
      >
        <Typography className="text-white">⌫</Typography>
      </Button>
    </div>
  );
};

export default VirtualKeyboard;
