import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';

interface ModelProps {
  path: string;
}

const Model: React.FC<ModelProps> = ({ path }) => {
  const { scene } = useGLTF(path);
  return <primitive object={scene} scale={8} />;
};

const ModelViewer: React.FC<ModelProps> = ({ path }) => {
  return (
    <div style={{ width: '90%', maxWidth: '90vw', height: '90%', maxHeight: '90vh', margin: '0 auto' }}>
      <Canvas camera={{ position: [0, 0, 5.5] }}>
        <ambientLight intensity={2} />
        <directionalLight position={[2, 2, 2]} intensity={1.5} />
        <spotLight position={[-2, 5, 5]} angle={0.3} intensity={1.2} penumbra={1} />
        <spotLight position={[2, 5, 5]} angle={0.3} intensity={1.2} penumbra={1} />
        <pointLight position={[0, 3, 0]} intensity={0.8} />
        <Model path={path} />
        <OrbitControls
          enableZoom={false}
          autoRotate
          autoRotateSpeed={1.5}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={Math.PI / 2}
        />
      </Canvas>
    </div>
  );
};

export default ModelViewer;
