import { envVars } from '@/config';

import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchCustomBaseQuery } from '../fetcher';

export const quizApi = createApi({
  reducerPath: 'quizApi',
  baseQuery: fetchCustomBaseQuery({
    baseUrl: envVars.backendUrl,
    mock: false,
  }),
  tagTypes: ['quiz'],

  endpoints: (builder) => ({
    getCompetitions: builder.query({
      query: () => '/final-phases/competitions',
    }),

    startQuiz: builder.mutation({
      query: (body) => ({
        url: '/final-phases/quiz',
        method: 'POST',
        body,
        isProtected: false,
      }),
    }),

    updateQuiz: builder.mutation({
      query: ({ playerId, score, time_wasted, level, correct_answers }) => ({
        url: `/final-phases/quiz/${playerId}`,
        method: 'PUT',
        body: { score, time_wasted, level, correct_answers },
        isProtected: false,
      }),
    }),

    getQuizSteps: builder.query({
      query: () => ({
        url: '/final-phases/quiz-steps',
        method: 'GET',
        isProtected: false,
      }),
    }),

    getLeaderboard: builder.query({
      query: () => '/final-phases/quiz-leaderboard',
    }),

    getScreenSaver: builder.query<
      { id: number; fpf_title: string; fpf_logo: { path: string; mime_type: string; caption: string | null }; fpf_subtitle: string, title: string, subtitle: string, secondary_logo: {  path: string; mime_type: string; caption: string | null } } | null,
      void
    >({
      query: () => ({
        url: '/final-phases/screensaver',
        isProtected: false,
      }),
      transformResponse: (response: {
        code: number;
        data: {
          screensaver: {
            id: number;
            fpf_title: string;
            fpf_logo: { path: string; mime_type: string; caption: string | null };
            fpf_subtitle: string;
            title: string;
            subtitle: string;
            secondary_logo: { path: string; mime_type: string; caption: string | null };
          } | null;
        };
        errors: null;
      }) => response.data?.screensaver ?? null,
    }),
  }),
});

export const {
  useGetCompetitionsQuery,
  useStartQuizMutation,
  useUpdateQuizMutation,
  useGetQuizStepsQuery,
  useGetLeaderboardQuery,
} = quizApi;
