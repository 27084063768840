import { Typography } from "@/core/ui";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface QuizHeaderProps {
  data: {
    currentLevel: number;
    currentQuestion: number;
    totalQuestions: number;
    score: number;
    time: number;
    answerStatus: ("correct" | "incorrect" | "unanswered")[];
  };
}

export const QuizHeader: React.FC<QuizHeaderProps> = ({ data }) => {
  const { t } = useTranslation("translation");

  const {
    currentLevel,
    currentQuestion,
    totalQuestions,
    score,
    time,
    answerStatus,
  } = data;
  const [remainingTime, setRemainingTime] = useState(time);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    if (remainingTime <= 0) return;

    const timerId = setTimeout(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearTimeout(timerId);
  }, [remainingTime]);

  return (
    <div className="flex justify-between w-full h-80 space-x-12">
      <div className="flex flex-col justify-center items-center w-[55%] bg-gray-100 rounded-[50px] shadow-lg py-6 px-12 space-y-6">
        <div className="flex justify-around w-full bg-neutral-300 rounded-[50px] py-4 px-2">
          {Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className="text-center">
              <div
                className={`px-6 py-2 rounded-full ${
                  index + 1 === currentLevel
                    ? "bg-neutral-700 text-white"
                    : "bg-transparent text-neutral-600"
                }`}
              >
                <Typography
                  className={`font-semibold uppercase ${
                    index + 1 === currentLevel
                      ? "text-white"
                      : "text-neutral-600"
                  }`}
                >
                  {`${t("quiz.play.header.level")} ${index + 1}`}
                </Typography>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-around w-full">
          {Array.from({ length: totalQuestions }).map((_, index) => (
            <div key={index} className="flex flex-col items-center">
              <div
                className={`w-24 h-24 flex items-center justify-center rounded-full ${
                  answerStatus[index] === "correct"
                    ? "bg-green-500 text-white"
                    : answerStatus[index] === "incorrect"
                    ? "bg-red-500 text-white"
                    : index === currentQuestion - 1
                    ? "border-4 border-gray-700 text-gray-700"
                    : "border-4 border-gray-400 text-gray-400"
                }`}
              >
                {answerStatus[index] === "correct" ? (
                  <span className="text-2xl">✓</span>
                ) : answerStatus[index] === "incorrect" ? (
                  <span className="text-2xl">✕</span>
                ) : (
                  <Typography className="text-5xl font-semibold">
                    {index + 1}
                  </Typography>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex w-[45%] flex-row items-center space-x-12 px-16 py-8 bg-white rounded-[50px] shadow-lg">
        <div className="flex flex-col items-center flex-1">
          <Typography className="uppercase text-neutral-600 text-[40px]">
            {t("quiz.play.header.score")}
          </Typography>
          <div className="flex items-center justify-center w-full bg-green-600 rounded-[50px] px-8 py-4 h-[184px]">
            <Typography className="text-8xl text-white font-bold">
              {score}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col items-center flex-1">
          <Typography className="uppercase text-neutral-600 text-[40px]">
            {t("quiz.play.header.time")}
          </Typography>
          <div className="flex items-center justify-center w-full border-8 border-green-600 rounded-[50px] px-8 py-4 h-[184px]">
            <Typography className="text-8xl text-green-600 font-bold">
              {formatTime(remainingTime)}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
