import { Button, Typography } from "@/core/ui";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const Leaderboard: React.FC = () => {
  const { t } = useTranslation("translation");

  const { state } = useLocation();
  const {
    score,
    correctAnswers,
    totalQuestions,
    playerName,
    finalLevel,
    top11,
    position,
    playerId,
    difficulty,
  } = state || {};
  const navigate = useNavigate();

  if (!state) return <p>Invalid data</p>;

  const levelNumber = finalLevel?.replace("LEVEL_", "");

  return (
    <div className="mx-[192px] w-full">
      <div className="flex flex-col gap-y-9 bg-white rounded-[30px] shadow-lg p-12 max-w-[1700px] mx-auto text-center">
        <div className="flex justify-around text-center mt-8">
          <div className="flex flex-1 flex-col items-center">
            <Typography className="text-[40px] uppercase text-gray-700">
              {t("leaderboard.titles.level")}
            </Typography>
            <div className="bg-[#4b8f82] rounded-[10px] px-6 w-fit h-auto flex items-center justify-center">
              <Typography className="text-[150px] font-bold text-white">
                {levelNumber}
              </Typography>
            </div>
          </div>
          <div className="flex flex-1 flex-col items-center">
            <Typography className="text-[40px] uppercase text-gray-700">
              {t("leaderboard.titles.answers")}
            </Typography>
            <div className="bg-[#4b8f82] rounded-[10px] px-6 w-fit h-auto flex items-center justify-center">
              <Typography className="text-[150px] font-bold text-white">
                {`${correctAnswers}/${totalQuestions}`}
              </Typography>
            </div>
          </div>
          <div className="flex flex-1 flex-col items-center">
            <Typography className="text-[40px] uppercase text-gray-700">
              {t("leaderboard.titles.final_score")}
            </Typography>
            <div className="bg-[#4b8f82] rounded-[10px] px-6 w-fit h-auto flex items-center justify-center">
              <Typography className="text-[150px] font-bold text-white">
                {score}
              </Typography>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <Typography className="text-[120px] font-bold uppercase">
            {t("leaderboard.titles.congratulations")}
          </Typography>
          <Typography className="text-6xl mt-8 text-center text-gray-600">
            {t("leaderboard.titles.placeholder_message")}
          </Typography>
        </div>
      </div>
      <div className="bg-white rounded-[30px] shadow-lg p-8 w-full max-w-[1700px] mx-auto mt-8">
        {" "}
        <div className="flex text-neutral-600 border-[3px] rounded-[20px] border-black text-[40px] w-fit mx-auto">
          <Typography className="text-[40px] uppercase px-7 py-5">
            {t("leaderboard.titles.difficulty")}{" "}
            <strong className="text-black">{difficulty}</strong>
          </Typography>
        </div>
        <div className="overflow-auto mx-40 mt-8">
          <table className="w-full text-left table-fixed">
            <thead>
              <tr>
                <th className="p-2 w-1/12">
                  <Typography className="text-[40px] uppercase">
                    {t("leaderboard.table.class")}
                  </Typography>
                </th>
                <th className="p-2 w-3/12">
                  <Typography className="text-[40px] uppercase">
                    {t("leaderboard.table.name")}
                  </Typography>
                </th>
                <th className="p-2 w-2/12">
                  <Typography className="text-[40px] uppercase">
                    {t("leaderboard.table.level")}
                  </Typography>
                </th>
                <th className="p-2 w-3/12">
                  <Typography className="text-[40px] uppercase">
                    {t("leaderboard.table.answers")}
                  </Typography>
                </th>
                <th className="p-2 w-2/12">
                  <Typography className="text-[40px] uppercase">
                    {t("leaderboard.table.score")}
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {position === null
                ? top11
                    .slice(0, 10)
                    .map(
                      (
                        { id, name, level, correct_answers, score }: IQuizTop11,
                        idx: number
                      ) => {
                        const levelNumber = level.replace("LEVEL_", "");
                        return (
                          <tr
                            key={id}
                            className={`${
                              id === playerId ? "bg-neutral-300" : ""
                            }`}
                          >
                            <td className="p-2 w-1/12">
                              <Typography className="text-[30px] font-semibold uppercase border-[3px] rounded-full border-black flex justify-center items-center w-12 h-12 mx-auto">
                                {idx + 1}
                              </Typography>
                            </td>
                            <td className="p-2 w-3/12">
                              <Typography className="text-[30px] uppercase">
                                {name}
                              </Typography>
                            </td>
                            <td className="w-2/12">
                              <Typography className="bg-[#4b8f82] rounded-[10px] px-4 py-2 text-[30px] font-semibold text-white uppercase">
                                {levelNumber}
                              </Typography>
                            </td>
                            <td className="w-3/12">
                              <Typography className="bg-[#4b8f82] rounded-[10px] px-4 py-2 text-[30px] font-semibold text-white uppercase">
                                {`${correct_answers}/${totalQuestions}`}
                              </Typography>
                            </td>
                            <td className="p-2 w-2/12">
                              <Typography className="text-[30px] font-semibold uppercase">
                                {score}
                              </Typography>
                            </td>
                          </tr>
                        );
                      }
                    )
                : top11.map(
                    (
                      { id, name, level, correct_answers, score }: IQuizTop11,
                      idx: number
                    ) => {
                      const levelNumber = level.replace("LEVEL_", "");
                      return (
                        <tr
                          key={id}
                          className={`${
                            id === playerId ? "bg-neutral-300" : ""
                          }`}
                        >
                          <td className="p-2 w-1/12 text-center">
                            <Typography>{idx + 1}</Typography>
                          </td>
                          <td className="p-2 w-3/12 text-left">
                            <Typography>{name}</Typography>
                          </td>
                          <td className="p-2 w-2/12 text-center">
                            <Typography>{levelNumber}</Typography>
                          </td>
                          <td className="p-2 w-3/12 text-center">
                            <Typography>{correct_answers}</Typography>
                          </td>
                          <td className="p-2 w-2/12 text-center">
                            <Typography>{score}</Typography>
                          </td>
                        </tr>
                      );
                    }
                  )}

              {position !== null && (
                <>
                  <tr>
                    <td colSpan={5}>
                      <hr className="my-4 border-red-600" />
                    </td>
                  </tr>
                  <tr className="bg-neutral-300">
                    <td className="p-2 text-center w-1/12">
                      <Typography>{position}</Typography>
                    </td>
                    <td className="p-2 w-3/12 text-left">
                      <Typography>{playerName}</Typography>
                    </td>
                    <td className="p-2 w-2/12 text-center">
                      <Typography>{levelNumber}</Typography>
                    </td>
                    <td className="p-2 w-3/12 text-center">
                      <Typography>{`${correctAnswers}`}</Typography>
                    </td>
                    <td className="p-2 w-2/12 text-center">
                      <Typography>{score}</Typography>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Button
        className="uppercase bg-red-500 text-white rounded-lg py-3 px-6 mt-8 hover:bg-red-600 mx-auto"
        onClick={() => navigate("/")}
      >
        <Typography className="text-white">
          {t("leaderboard.buttons.exit_quiz")}
        </Typography>
      </Button>
    </div>
  );
};

export default Leaderboard;
