/**
 * export all store api's
 */

import { globalApi } from './global/api';
import { modalityApi } from './modality';
import { quizApi } from './quiz/api';
import { shirtApi } from './shirts/api';

export const API = {
  globalApi,
  quizApi,
  shirtApi,
  modalityApi
};

export type APIKeys = keyof typeof API;
export type APIType = (typeof API)[APIKeys];
