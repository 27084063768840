import { Loading, Maintenance, NotFound, ScreenSaver } from '@/core/blocks';
import { Route, Routes } from 'react-router-dom';
import { ROUTES, envVars } from './config';
import { useIdle } from './core';
import { Layout } from './layout';

import { PrivateRoutes } from './router';

import Home from './pages/home';
import Quiz from './pages/quiz';
import Leaderboard from './pages/quiz/leaderboard';
import QuizPlay from './pages/quiz/play';
import Shirts from './pages/shirts/id';

function App() {
  const { globalLoading } = useIdle();

  if (envVars.underMaintenance) {
    return <Maintenance />;
  }

  if (globalLoading) {
    return <Loading />;
  }

  return (
    <>
      <ScreenSaver />
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route element={<Layout />}>
            <Route path={ROUTES.HOME} Component={Home} />
            <Route path={ROUTES.QUIZ} Component={Quiz} />
            <Route path={ROUTES.QUIZ_PLAY} Component={QuizPlay} />
            <Route path={ROUTES.QUIZ_LEADERBOARD} Component={Leaderboard} />
          </Route>
          <Route path={ROUTES.SHIRTS.view} Component={Shirts} />
        </Route>
        <Route
          path={ROUTES.LOGIN}
          element={<div className='h-screen flex justify-center items-center text-8xl'>Login</div>}
        />
        <Route path='*' Component={NotFound} />
      </Routes>
    </>
  );
}

export default App;
