import { envVars } from '@/config';
import type { IGetModalitiesRes, IGetModalityCompetitionsReq, IGetModalityCompetitionsRes } from '@/types/modality';
import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchCustomBaseQuery } from '../fetcher';
import type { IModality, IModalityCompetitions } from '@/types/models/modality';

export const modalityApi = createApi({
  reducerPath: 'modalityApi',
  baseQuery: fetchCustomBaseQuery({
    baseUrl: envVars.backendUrl,
    mock: false,
  }),
  tagTypes: ['modality'],
  endpoints: (builder) => ({
    getAllModalities: builder.query<IModality[], void>({
      query: () => ({
        url: '/general/modalities',
        isProtected: false,
        forceMock: true,
      }),
      transformResponse: (response: IGetModalitiesRes) => response.data.modalities ?? [],
    }),
    getCompetitions: builder.query<IModalityCompetitions, IGetModalityCompetitionsReq>({
      query: ({ id }: { id: number }) => ({
        url: `/final-phases/competitions?modality_id=${id}`,
        isProtected: false,
        forceMock: true,
      }),
      transformResponse: (response: IGetModalityCompetitionsRes) => response.data ?? {},
    }),
  }),
});

export const { useGetAllModalitiesQuery, useGetCompetitionsQuery } = modalityApi;
