import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ShirtState {
  shirtId: number;
  loading: boolean;
  error: string | null;
}

const initialState: ShirtState = {
  shirtId: 0,
  loading: false,
  error: null,
};

export const shirtSlice = createSlice({
  name: 'shirt',
  initialState,
  reducers: {
    setShirt: (state, action: PayloadAction<ShirtState['shirtId']>) => {
	state.shirtId = action.payload;
	state.loading = false;
	state.error = null;
	}
  },
});

export const { setShirt } = shirtSlice.actions;

export default shirtSlice.reducer;
