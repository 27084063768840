import { Button, Typography } from "@/core/ui";

interface ButtonConfig {
  label: string;
  onClick: () => void;
}

interface PopupProps {
  headerText: string;
  bodyContent: string | JSX.Element;
  buttons: ButtonConfig[];
  showCloseButton?: boolean;
  onClose?: () => void;
}

export const Popup = ({
  headerText,
  bodyContent,
  buttons,
  showCloseButton = false,
  onClose,
}: PopupProps) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-[#E7F2F1] rounded-[20px] p-8 w-[90%] max-w-[600px] relative shadow-lg">
        {showCloseButton && (
          <Button
            onClick={onClose}
            className="bg-transparent absolute top-4 right-4 w-8 h-8 rounded-full border border-red-600 flex items-center justify-center hover:bg-transparent"
          >
            <Typography className="text-red-600">x</Typography>
          </Button>
        )}

        <Typography className="uppercase text-center text-[30px] font-bold mb-4">
          {headerText}
        </Typography>

        <div className="text-center text-[20px] mb-8">
          {typeof bodyContent === "string" ? (
            <Typography>{bodyContent}</Typography>
          ) : (
            bodyContent
          )}
        </div>

        <div className="flex flex-col justify-center space-y-4">
          {buttons.map((button, index) => (
            <Button
              key={index}
              onClick={button.onClick}
              className={`px-6 py-3 rounded-md font-semibold text-[20px] border border-black bg-white
                transition duration-200 ease-in-out
                hover:bg-black
                hover:text-white
                focus:bg-black
                active:bg-black`}
            >
              <Typography className="focus:text-white hover:text-white active:text-white">
                {button.label}
              </Typography>
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};
