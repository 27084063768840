import { envVars } from '@/config';

import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchCustomBaseQuery } from '../fetcher';

export const shirtApi = createApi({
  reducerPath: 'shirtApi',
  baseQuery: fetchCustomBaseQuery({
    baseUrl: envVars.backendUrl,
    mock: false,
  }),
  tagTypes: ['quiz'],

  endpoints: (builder) => ({
    getShirtDetails: builder.query({
      query: ({ shirtId }) => ({
        url: `/final-phases/competition/${shirtId}`,
        method: 'GET',
        isProtected: false,
      }),
      transformResponse: (response: { data: any }) => response.data,
    }),
  }),
});

export const {
  useGetShirtDetailsQuery,
} = shirtApi;
