import ModelViewer from '@/components/ModelViewer';
import { PageHeader } from '@/components/PageHeader';
import { ROUTES } from '@/config';
import { Icon } from '@/core/icon';
import { Button, Card, CardContent, Carousel, CarouselContent, CarouselItem, Typography } from '@/core/ui';
import { LanguageSwitcher } from '@/layout/lang-switcher';
import { API } from '@/store/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { cx } from '@/lib';

import { useNavigate } from 'react-router-dom';

import type { ICompetition, IModality } from '@/types/models/modality';

const Home = () => {
  const { data: modalities } = API.modalityApi.useGetAllModalitiesQuery();
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  const [selectedModality, setSelectedModality] = useState<string>('Football');
  const [selectedCompetition, setSelectedCompetition] = useState<ICompetition>();
  const [modalityId, setModalityId] = useState<number>(1);
  const [selectedGender, setSelectedGender] = useState<string>('Male');
  const [showModalities, setShowModalities] = useState<boolean>(false);
  const [showGenders, setShowGenders] = useState<boolean>(false);

  const handleInitialButtonClick = () => {
    setShowModalities(true);
  };

  const handleIconClick = () => {
    if (selectedCompetition) {
      navigate(`/shirt/${selectedCompetition.id}`);
    }
  };

  const handleModalitySelect = (id: number, modality: string) => {
    setSelectedModality(modality);
    setModalityId(id);
    setShowModalities(false);
    setShowGenders(true);
  };

  const handleGenderSelect = (gender: string) => {
    setSelectedGender(gender);
    setShowGenders(false);
  };

  console.log('selectedGender', selectedGender);

  const { data: apiResponse, isLoading } = API.modalityApi.useGetCompetitionsQuery({
    id: modalityId,
  });
  const appTitle = apiResponse?.app_title;
  const competitions = apiResponse?.competitions;

  useEffect(() => {
    if (competitions && competitions.length > 0 && !selectedCompetition) {
      setSelectedCompetition(competitions[0]);
    }
  }, [competitions, selectedCompetition]);

  return (
    <div className='mt-[88px] h-full flex items-center justify-between flex-col w-full'>
      <PageHeader
        data={{
          title: appTitle ?? '',
          secondaryTitle: selectedCompetition ? selectedCompetition.name : t('home.subtitle'),
          tertiaryTitle: selectedCompetition ? `${selectedCompetition.year}` : t('home.tertiaryTitle'),
        }}
      />
      <div className='h-full w-full my-auto relative'>
        <div className='absolute z-[10] top-[52%] rounded-full bg-olive/60 left-[28%] backdrop-blur-lg'>
          <Icon name='PLUS' className='h-52 w-52' onClick={handleIconClick} />
        </div>
        {selectedCompetition?.shirt_3d_model?.path ? (
          <ModelViewer path={selectedCompetition.shirt_3d_model.path} />
        ) : null}
      </div>
      <div className='flex flex-col w-full px-44 pb-28'>
        <div className='flex justify-center'>
          <span />
          <div className='flex flex-row space-x-10 items-center'>
            <Icon name='CHEVRON_LEFT' className='w-14 h-14' color='rgb(var(--color-content1))' />
            <Icon name='THREE_D' />
            <Icon name='CHEVRON_RIGHT' className='w-14 h-14' color='rgb(var(--color-content1))' />
          </div>
        </div>
        <div className='flex relative justify-end z-20'>
          <span />
          <Link
            to={ROUTES.QUIZ}
            className='bg-danger cursor-pointer rounded-full px-16 py-14 flex items-center justify-center'
          >
            <Typography className='uppercase font-semibold text-[33px] leading-[31px] text-center text-chalk'>
              {t('home.buttons.quiz_challenge')}
            </Typography>
          </Link>
        </div>
      </div>
      <div className='flex flex-col w-full'>
        {!isLoading && competitions && (
          <ShirtCarousel
            competitions={competitions}
            selectedCompetition={selectedCompetition}
            onCompetitionClick={setSelectedCompetition}
          />
        )}
      </div>
      <div className='flex justify-between items-center w-full mt-20 px-48'>
        <div className='flex flex-row space-x-10'>
          {selectedModality && !showModalities && !showGenders && (
            <Button
              className='bg-danger rounded-full px-16 py-14 items-center justify-center'
              onClick={handleInitialButtonClick}
            >
              <Typography className='uppercase font-semibold text-[33px] leading-[31px] text-center text-chalk'>
                {selectedModality}
              </Typography>
            </Button>
          )}
          {showModalities &&
            modalities?.map((modality: IModality) => (
              <Button
                key={modality.id}
                className='bg-danger rounded-full px-8 py-4 items-center justify-center'
                onClick={() => handleModalitySelect(modality.id, modality.name)}
              >
                <Typography className='uppercase font-semibold text-[20px] leading-[20px] text-center text-chalk'>
                  {modality.name}
                </Typography>
              </Button>
            ))}
          {showGenders && (
            <div className='flex space-x-4'>
              <Button
                className='bg-danger rounded-full px-8 py-4 items-center justify-center'
                onClick={() => handleGenderSelect('male')}
              >
                <Typography className='uppercase font-semibold text-[20px] leading-[20px] text-center text-chalk'>
                  {t('home.filters.male')}
                </Typography>
              </Button>
              <Button
                className='bg-danger rounded-full px-8 py-4 items-center justify-center'
                onClick={() => handleGenderSelect('female')}
              >
                <Typography className='uppercase font-semibold text-[20px] leading-[20px] text-center text-chalk'>
                  {t('home.filters.female')}
                </Typography>
              </Button>
            </div>
          )}
        </div>
        <LanguageSwitcher />
      </div>
    </div>
  );
};

export default Home;

const ShirtCarousel = ({
  competitions = [],
  selectedCompetition,
  onCompetitionClick,
}: {
  competitions: ICompetition[];
  selectedCompetition: ICompetition | undefined;
  onCompetitionClick: (competition: ICompetition) => void;
}) => {
  return (
    <Carousel className='w-full'>
      <CarouselContent className='w-full px-20 pl-32'>
        {competitions?.map((competition) => (
          <CarouselItem
            key={competition.id}
            className='basis-[21.8%]'
            onClick={() => onCompetitionClick(competition)}
          >
            <Card
              className={cx(
                'rounded-[26px] w-[383px] h-[383px]',
                competition.id === selectedCompetition?.id ? 'border-4 border-[#509B85]' : '',
              )}
            >
              <CardContent className='flex flex-col items-center justify-center h-full shadow-xl'>
                <img
                  src={competition.home_shirt?.path || '/shirts/portugal-shirt.png'}
                  alt={competition.home_shirt?.caption}
                  className='w-[180px] h-[200px] object-contain'
                />
                <Typography className='text-[37px] leading-[35px] text-center tracking-[1.85px] font-semibold absolute bottom-10 max-w-80'>
                  {competition.name}
                </Typography>
              </CardContent>
            </Card>
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  );
};
