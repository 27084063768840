import { PageHeader } from "@/components/PageHeader";
import { Button, Typography } from "@/core/ui";
import { Key, useState } from "react";
import { Popup } from "@/components/Popup";
import VirtualKeyboard from "@/components/VirtualKeyboard";
import { ROUTES } from "@/config";
import { Link, useNavigate } from "react-router-dom";
import { API } from "@/store/api";
import { useTranslation } from "react-i18next";
import QuizLoading from "@/components/quiz/QuizLoading";

const chunkArray = (array: any[], chunkSize: number) => {
  return array.reduce((acc: any[], item: any, index: number) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }
    acc[chunkIndex].push(item);
    return acc;
  }, []);
};

const Quiz = () => {
  const { t } = useTranslation("translation");

  const [playerName, setPlayerName] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const { isLoading, isFetching, error, data } =
    API.quizApi.useGetQuizStepsQuery({
      locale: i18n.language,
    });

  const quizSteps = data?.data?.steps || [];

  const chunkedQuizSteps = chunkArray(quizSteps, 2);

  const handleStartQuiz = () => setIsPopupVisible(true);
  const handleClosePopup = () => setIsPopupVisible(false);
  const isStartQuizDisabled = () => playerName.trim() === "";
  const handleDifficultyLevel = (difficulty: string) => {
    navigate(ROUTES.QUIZ_PLAY, {
      state: { playerName, difficulty },
    });
  };

  const QuizInstruction = ({ step, text }: { step: number; text: string }) => (
    <div className="relative flex flex-col items-center justify-center space-y-4">
      <Typography className="text-[250px] font-bold absolute top-32 left-36 transform -translate-x-full -translate-y-1/2 text-neutral-300">
        {step}
      </Typography>
      <Typography className="text-[60px] leading-[70px] text-center text-neutral-800 z-10">
        {text}
      </Typography>
    </div>
  );

  const Divider = () => (
    <div className="flex justify-center my-16">
      <div className="h-[6px] w-[90%] flex">
        <div className="flex-1 bg-green-500"></div>
        <div className="flex-1 bg-red-500"></div>
      </div>
    </div>
  );

  const QuizButton = ({
    text,
    onClick,
    to,
    disabled = false,
  }: {
    text: string;
    onClick?: () => void;
    to?: string;
    disabled?: boolean;
  }) => {
    const buttonContent = (
      <Button
        className="uppercase bg-danger rounded-full px-32 py-20 flex items-center justify-center disabled:opacity-50"
        onClick={onClick}
        disabled={disabled}
      >
        <Typography className="text-[60px] text-white">{text}</Typography>
      </Button>
    );

    return to ? <Link to={to}>{buttonContent}</Link> : buttonContent;
  };

  if (error) {
    return <p>Oh no, there was an error</p>;
  }

  if (isLoading || isFetching || !quizSteps) {
    return <QuizLoading />;
  }

  return (
    <div className="pt-60 h-full flex items-center justify-between flex-col w-full space-y-24">
      <header className="w-full flex flex-col items-center space-y-12 px-[192px]">
        <PageHeader
          data={{
            title: t("quiz.title"),
            secondaryTitle: t("quiz.subtitle"),
          }}
        />
        <div className="bg-[#FCFBF9] rounded-[50px] p-24 w-full shadow-lg">
          {chunkedQuizSteps.map((chunk, chunkIndex) => (
            <div key={chunkIndex}>
              <div className="grid grid-cols-2 gap-16 text-[60px] leading-[70px]">
                {chunk.map(
                  (stepData: { id: Key; value: string }, index: number) => (
                    <QuizInstruction
                      key={stepData.id}
                      step={chunkIndex * 2 + index + 1}
                      text={stepData.value}
                    />
                  )
                )}
              </div>
              {chunkedQuizSteps[chunkIndex + 1] && <Divider />}
            </div>
          ))}
        </div>
      </header>

      <div className="w-[80%] max-w-max flex flex-col items-center space-y-8">
        <Typography className="uppercase text-[60px] tracking-wide mb-6">
          {t("quiz.player.name")}
        </Typography>
        <input
          type="text"
          value={playerName}
          onChange={(e) => setPlayerName(e.target.value)}
          placeholder={t("quiz.player.placeholder")}
          className="w-full text-center text-[60px] p-10 border-b border-gray-400 focus:outline-none"
          style={{ fontFamily: "united-semi-sans, United Sans SmCd Md" }}
        />
        <VirtualKeyboard setPlayerName={setPlayerName} />
      </div>

      <div className="flex justify-between w-full px-48 mt-32 space-x-12">
        <QuizButton text={t("quiz.buttons.exit_quiz")} to={ROUTES.HOME} />
        <QuizButton
          text={t("quiz.buttons.new_quiz")}
          onClick={handleStartQuiz}
          disabled={isStartQuizDisabled()}
        />
      </div>

      {isPopupVisible && (
        <Popup
          headerText={`${t(
            "quiz.popup.greeting"
          )}, ${playerName.toUpperCase()}!`}
          bodyContent={t("quiz.popup.difficulty_prompt")}
          buttons={[
            {
              label: t("quiz.popup.easy"),
              onClick: () => handleDifficultyLevel("EASY"),
            },
            {
              label: t("quiz.popup.medium"),
              onClick: () => handleDifficultyLevel("MEDIUM"),
            },
            {
              label: t("quiz.popup.hard"),
              onClick: () => handleDifficultyLevel("HARD"),
            },
          ]}
          showCloseButton
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default Quiz;
