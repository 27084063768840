import { Typography } from "@/core/ui";

const QuizLoading = () => {
  return (
    <div className="pt-36 h-full flex items-center justify-between flex-col w-full">
      <header className="w-full flex flex-col items-center">
        <div className="animate-pulse bg-[#FCFBF9] rounded-[20px] p-16 w-[75%]">
          <div className="grid grid-cols-2 gap-x-12 text-[40px] leading-[50px]">
            {[1, 2].map((step) => (
              <div key={step} className="flex flex-col items-center space-y-4">
                <div className="h-[200px] w-[200px] bg-gray-300 rounded-full"></div>
                <div className="w-[280px] h-[40px] bg-gray-300 rounded-md"></div>
              </div>
            ))}
          </div>

          <div className="flex justify-center my-8">
            <div className="h-[3px] w-[90%] flex">
              <div className="flex-1 bg-green-300"></div>
              <div className="flex-1 bg-red-300"></div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-x-12 text-[40px] leading-[50px]">
            {[3, 4].map((step) => (
              <div key={step} className="flex flex-col items-center space-y-4">
                <div className="h-[200px] w-[200px] bg-gray-300 rounded-full"></div>
                <div className="w-[280px] h-[40px] bg-gray-300 rounded-md"></div>
              </div>
            ))}
          </div>
        </div>
      </header>

      <div className="w-[75%] flex flex-col items-center mt-12 animate-pulse">
        <Typography className="text-[40px] tracking-[3px] mb-4 bg-gray-300 w-1/3 h-[40px] rounded-md"></Typography>
        <div className="w-full text-center text-[40px] p-6 bg-gray-300 rounded-md border-b border-gray-400 focus:outline-none h-[80px]"></div>
      </div>

      <div className="flex justify-between w-full px-48 mt-20 animate-pulse">
        <div className="bg-gray-300 cursor-pointer rounded-full px-16 py-14 w-[220px] h-[80px] flex items-center justify-center"></div>
        <div className="bg-gray-300 cursor-pointer rounded-full px-16 py-14 w-[220px] h-[80px] flex items-center justify-center"></div>
      </div>
    </div>
  );
};

export default QuizLoading;
