import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface QuizState {
  quizData: any;
  difficulty: 'EASY' | 'MEDIUM' | 'HARD' | null;
  loading: boolean;
  error: string | null;
}

const initialState: QuizState = {
  quizData: null,
  difficulty: null,
  loading: false,
  error: null,
};

export const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setQuizData: (state, action: PayloadAction<any>) => {
      state.quizData = action.payload;
      state.loading = false;
      state.error = null;
    },
    setDifficulty: (state, action: PayloadAction<QuizState['difficulty']>) => {
      state.difficulty = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetQuiz: (state) => {
      state.quizData = null;
      state.difficulty = null;
      state.loading = false;
      state.error = null;
    },
  },
});

export const { setQuizData, setDifficulty, setLoading, setError, resetQuiz } = quizSlice.actions;

export default quizSlice.reducer;
