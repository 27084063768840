import { envVars } from '@/config';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { globalApi, globalReducer } from './global';
import { quizApi, quizReducer } from './quiz';
import { modalityReducer } from './modality';
import { modalityApi } from './modality';
import { shirtApi } from './shirts';

export const store = configureStore({
  reducer: {
    global: globalReducer,
    quiz: quizReducer,
    modality: modalityReducer,
    [globalApi.reducerPath]: globalApi.reducer,
    [quizApi.reducerPath]: quizApi.reducer,
    [modalityApi.reducerPath]: modalityApi.reducer,
    [shirtApi.reducerPath]: shirtApi.reducer,
  },
  devTools: !envVars.prod,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({ serializableCheck: false }).concat(
      globalApi.middleware,
      quizApi.middleware,
      modalityApi.middleware,
      shirtApi.middleware
    );
  },
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
