import { GallerySlider } from '@/core/blocks';
import type { IPicture } from '@/types';

interface ICompetition {
  gallery: IPicture[];
}

export const Section3 = ({ competition }: { competition: ICompetition }) => {
  return (
    <div className='h-[756px]'>
      <GallerySlider slides={competition.gallery} />
    </div>
  );
};
