import { QuizHeader } from "@/components/quiz/QuizHeader";
import { QuizContent } from "@/components/quiz/QuizContent";
import { useLocation, useNavigate } from "react-router-dom";

import { useQuizPlay } from "@/core/hooks/useQuizPlay/useQuizPlay";
import { ROUTES } from "@/config";
import { useEffect, useState } from "react";

import { API } from "@/store/api";
import { Popup } from "@/components/Popup";
import { useTranslation } from "react-i18next";

const QuizPlay: React.FC = () => {
  const { t } = useTranslation("translation");
  const { state } = useLocation();
  const { playerName, difficulty } = state || {};
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const navigate = useNavigate();

  const {
    quizData,
    questionStatus,
    currentLevelIndex,
    currentQuestionIndex,
    handleAnswerClick,
    isLoading,
    error,
    quizCompleted,
    score,
    correctAnswers,
    playerId,
    elapsedTime,
  } = useQuizPlay(playerName, difficulty);

  const [updateQuiz] = API.quizApi.useUpdateQuizMutation();

  const handleNewQuiz = () => {
    setIsPopupVisible(false);
    navigate(ROUTES.QUIZ, {
      state: { playerName: playerName, difficulty: difficulty },
    });
  };

  const handleViewLeaderboard = () => {
    setIsPopupVisible(false);
    navigate(ROUTES.QUIZ_LEADERBOARD, {
      state: {
        playerId: playerId,
        playerName,
        score,
        correctAnswers,
        finalLevel: quizData?.levels.length ?? 0,
        difficulty,
      },
    });
  };

  useEffect(() => {
    if (elapsedTime === quizData?.timer && !quizCompleted) {
      setIsPopupVisible(true);
    }
  }, [elapsedTime, quizData?.timer, quizCompleted]);

  useEffect(() => {
    if (quizCompleted && quizData && playerId) {
      const nonEmptyLevels = quizData.levels.filter(
        (level) => level.questions.length > 0
      );
      const formattedLevel = `LEVEL_${nonEmptyLevels.length}`;

      const quizUpdateData = {
        score: score,
        time_wasted: elapsedTime,
        level: formattedLevel,
        correct_answers: correctAnswers,
      };

      updateQuiz({ ...quizUpdateData, playerId: playerId })
        .unwrap()
        .then((response) => {
          const leaderboardData = response.data;

          navigate(ROUTES.QUIZ_LEADERBOARD, {
            state: {
              playerId: playerId,
              playerName: leaderboardData.player.info.name,
              score: leaderboardData.player.info.score,
              correctAnswers: leaderboardData.player.info.correct_answers,
              totalQuestions: quizData.levels.reduce(
                (total, level) => total + level.questions.length,
                0
              ),
              finalLevel: leaderboardData.player.info.level,
              top11: leaderboardData.top11,
              position: leaderboardData.player.position,
              difficulty: difficulty,
            },
          });
        })
        .catch((error) => {
          console.error("Error updating quiz:", error);
        });
    }
  }, [quizCompleted, quizData, score, correctAnswers, navigate, updateQuiz]);

  if (error) return <p>Oh no, there was an error</p>;
  if (isLoading || !quizData) return <p>Loading...</p>;

  const currentLevel = quizData.levels[currentLevelIndex];
  const currentQuestion = currentLevel.questions[currentQuestionIndex];

  const headerData = {
    currentLevel: currentLevelIndex + 1,
    currentQuestion: currentQuestionIndex + 1,
    totalQuestions: currentLevel.questions.length,
    score: score,
    time: quizData.timer,
    answerStatus: questionStatus,
  };

  if (!currentQuestion) return;
  const contentData = {
    imageSrc: currentQuestion.image.path,
    question: currentQuestion.question,
    answers: {
      option_a: currentQuestion.option_a,
      option_b: currentQuestion.option_b,
      option_c: currentQuestion.option_c,
      option_d: currentQuestion.option_d,
    },
    currentLevel: currentLevel.level,
    currentQuestion: currentQuestionIndex + 1,
  };

  return (
    <div className="mx-auto max-w-max px-[192px]">
      <QuizHeader data={headerData} />
      <QuizContent data={contentData} onAnswerClick={handleAnswerClick} />
      {isPopupVisible && (
        <Popup
          headerText={t("quiz.play.popup.headerText")}
          bodyContent={t("quiz.play.popup.bodyContent")}
          buttons={[
            {
              label: t("quiz.play.popup.buttons.new_quiz"),
              onClick: handleNewQuiz,
            },
            {
              label: t("quiz.play.popup.buttons.view_leaderboard"),
              onClick: handleViewLeaderboard,
            },
            {
              label: t("quiz.play.popup.buttons.exit_quiz"),
              onClick: () => navigate(ROUTES.HOME),
            },
          ]}
        />
      )}
    </div>
  );
};

export default QuizPlay;
