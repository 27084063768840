import { LANGUAGES } from '@/config';
import { Icon } from '@/core/icon';
import { Button, Typography } from '@/core/ui';
import { cn } from '@/lib';
import { useTranslation } from 'react-i18next';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation('translation');

  const changeLanguage = (lng: LANGUAGES) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className='flex flex-row space-x-8'>
      <div className='flex flex-row space-x-6'>
        {Object.entries([LANGUAGES.PORTUGUESE]).map(([, lng]) => (
          <Button
            type='button'
            onClick={() => changeLanguage(lng)}
            key={lng}
            className={cn(
              'h-[108px] w-[108px] flex items-center justify-center',
              'bg-chalk text-center rounded-full border border-content2',
              { 'border-danger': lng === i18n.language },
            )}
          >
            <Typography
              className={cn('text-[37px] text-content2 pt-0.5 font-semibold uppercase', {
                'text-danger': lng === i18n.language,
              })}
            >
              {lng}
            </Typography>
          </Button>
        ))}
      </div>
      <div className='h-[108px] w-[108px] bg-danger flex items-center justify-center text-center rounded-full'>
        <Icon name='GLOBE' className='' />
      </div>
    </div>
  );
};
