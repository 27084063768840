import { Card, CardContent, Typography } from '@/core/ui';
import { cn } from '@/lib';

interface ITeam {
  Position: number;
  CountryName: string;
  FifaCountryCode: string;
  Matches: number;
  Victories: number;
  Draws: number;
  Losses: number;
  GoalsScored: number;
  GoalsConceded: number;
  Points: number;
}

interface IMatch {
  MatchId: number;
  MatchDate: string;
  HomeTeam: string;
  AwayTeam: string;
  HomeScore: number | null;
  AwayScore: number | null;
  HomeFifaCountryCode: string;
  AwayFifaCountryCode: string;
  PhaseName: string;
}

interface IExtraInfo {
  group_stage_teams: ITeam[];
  matches: {
    group_stage: IMatch[];
    round_of_16: IMatch | null;
    quarter_finals: IMatch | null;
    semi_finals: IMatch | null;
    final: IMatch | null;
  };
}

interface Section2Props {
  extraInfo: IExtraInfo;
}

interface ChartProps {
  groupStageTeams: ITeam[];
}

interface GroupProps {
  match: IMatch;
}

interface EliminationsProps {
  matches: IMatch[];
}

const Group: React.FC<GroupProps> = ({ match }) => (
  <div className='flex flex-col'>
    <Typography className='text-2xl leading-[30px] tracking-[1.2px] font-semibold'>
      {match.PhaseName}
    </Typography>
    <Typography className='text-2xl leading-[30px] tracking-[1.2px]'>
      {new Date(match.MatchDate).toLocaleDateString()}
    </Typography>
    <Typography className='text-2xl leading-[30px] tracking-[1.2px]'>
      {new Date(match.MatchDate).toLocaleTimeString()}
    </Typography>
    <div className='flex mt-4 space-x-3 items-center'>
      <Typography className='text-3xl leading-[34.5px] font-bold'>{match.HomeScore}</Typography>
      {/* <Icon name={`${match.HomeFifaCountryCode}_FLAG`} /> */}
      <Typography className='text-2xl leading-[27.6px] tracking-[1.2px] font-semibold'>
        {match.HomeTeam}
      </Typography>
    </div>
    <div className='flex mt-4 space-x-3 items-center'>
      <Typography className='text-3xl leading-[34.5px] font-bold'>{match.AwayScore}</Typography>
      {/* <Icon name={`${match.AwayFifaCountryCode}_FLAG`} /> */}
      <Typography className='text-2xl leading-[27.6px] tracking-[1.2px] font-semibold'>
        {match.AwayTeam}
      </Typography>
    </div>
  </div>
);

const Chart: React.FC<ChartProps> = ({ groupStageTeams }) => (
  <div className='flex flex-col mt-14 space-y-4'>
    <div className='flex justify-between'>
      <Typography>GRUPO F · CLASSIFICAÇÃO</Typography>
      <div className='flex space-x-11 items-center'>
        {['P', 'J', 'V', 'E', 'D', 'GM', 'GS'].map((item, index) => (
          <div key={index}>
            <Typography>{item}</Typography>
          </div>
        ))}
      </div>
    </div>
    {groupStageTeams.map((team, index) => (
      <div
        key={`${team.CountryName}-${team.Position}`}
        className={cn('border-content3', { 'border-b': index !== groupStageTeams.length - 1 })}
      >
        <div className='flex flex-row space-x-12'>
          <div className='flex space-x-4'>
            <Typography className='text-2xl leading-[27.6px] tracking-[1.2px]'>{team.Position}</Typography>
            {/* <div className='pl-3'>
              <Icon name={`${team.FifaCountryCode}_FLAG`} />
            </div> */}
            <Typography className='text-2xl leading-[27.6px] tracking-[1.2px]'>{team.CountryName}</Typography>
          </div>
          <div className='pl-24 flex space-x-12'>
            {[
              team.Points,
              team.Matches,
              team.Victories,
              team.Draws,
              team.Losses,
              team.GoalsScored,
              team.GoalsConceded,
            ].map((stat, statIndex) => (
              <div key={statIndex}>
                <Typography>{stat}</Typography>
              </div>
            ))}
          </div>
        </div>
      </div>
    ))}
  </div>
);

const Eliminations: React.FC<EliminationsProps> = ({ matches }) => (
  <div className='flex flex-col items-center -mt-16'>
    <Typography className='text-[34px] leading-[34px] tracking-[1.7px] font-semibold'>
      ELIMINATORIAS
    </Typography>
    <div className='flex w-full justify-center items-center mt-14 space-x-8'>
      {matches.map((match) => (
        <div
          key={match.MatchId}
          className='flex bg-chalk rounded-[50px] pl-16 items-center shadow-board w-[375px] h-[311px]'
        >
          <Group match={match} />
        </div>
      ))}
    </div>
  </div>
);

export const Section2: React.FC<Section2Props> = ({ extraInfo }) => {
  return (
    <Card className='w-full shadow-board rounded-[40px]'>
      <CardContent>
        <div className='w-full h-full flex flex-col pt-24'>
          <div className='flex flex-row justify-between space-x-32 px-24'>
            <div>
              <Typography className='text-[34px] leading-[34px] tracking-[1.7px] font-semibold'>
                FASE FINAL
              </Typography>
              <Chart groupStageTeams={extraInfo.group_stage_teams} />
            </div>
            <div>
              <Typography className='text-[34px] leading-[34px] tracking-[1.7px] font-semibold'>
                GRUPOS
              </Typography>
              <div className='flex w-full mt-14 space-x-20'>
                {extraInfo.matches.group_stage.map((match) => (
                  <Group key={match.MatchId} match={match} />
                ))}
              </div>
            </div>
          </div>
          <div className='bg-match bg-bottom -mb-20 bg-no-repeat h-[30rem] mt-36'>
            <Eliminations
              matches={Object.values(extraInfo.matches)
                .flat()
                .filter((match): match is IMatch => match !== null)}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
