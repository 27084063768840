import { ROUTES } from '@/config';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@/core/ui';
import { API } from '@/store/api';
import { useNavigate } from 'react-router-dom';
import { useIdle } from '../hooks';
import { Icon } from '../icon';

export const ScreenSaver = () => {
  const { data: screensaver, isLoading } = API.globalApi.useGetScreenSaverQuery({});
  const { onReset } = useIdle();
  const navigate = useNavigate();

  const handleClick = () => {
    onReset();
    navigate(ROUTES.MAIN);
  };

  if (isLoading) {
    return <></>;
  }

  const JSX = (
    <div className='flex h-screen bg-apple bg-cover flex-col justify-center items-center'>
      <video
        autoPlay
        muted
        loop
        playsInline
        className='absolute top-0 left-0 w-full h-full object-cover z-0'
        src='/background/hall-bg-loop.mp4'
      />
      <div className='z-10 mt-24'>
        <div className='flex items-center space-x-4'>
          {screensaver?.fpf_logo?.path ? (
            <img
              src={screensaver.fpf_logo.path}
              alt={screensaver.fpf_logo.caption || 'FPF Logo'}
              className='w-32 object-contain'
            />
          ) : (
            <Icon name='OBJECT_LOGO_TEXT' className='w-[271px]' />
          )}

          {screensaver?.secondary_logo?.path && <div className='h-full w-[1px] bg-gray-500' />}

          {screensaver?.secondary_logo?.path && (
            <img
              src={screensaver.secondary_logo.path}
              alt={screensaver.secondary_logo.caption || 'Secondary Logo'}
              className='w-32 object-contain'
            />
          )}
        </div>

        <div className='flex flex-col items-center space-y-8 mt-16'>
          <Typography className='font-united-sans text-[100px] font-bold tracking-[7%] leading-[95px] uppercase'>
            {screensaver?.title}
          </Typography>

          <Typography className='font-united-semi-sans text-[50px] font-medium tracking-[15%] leading-[50px] uppercase'>
            {screensaver?.subtitle}
          </Typography>

          <div className='relative flex justify-center items-center'>
            <div className='absolute inset-0 w-full h-full p-6 rounded-full animate-expand-ping bg-danger/20' />
            <Button
              onClick={handleClick}
              type='button'
              className='flex justify-center items-center rounded-full w-20 h-20 bg-danger relative'
            >
              <Icon name='CHEVRON_RIGHT' />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Dialog open={false} onOpenChange={handleClick}>
      {/* biome-ignore lint/complexity/noUselessFragments: <explanation> */}
      <DialogContent closeJsx={<></>} className='max-h-full max-w-full'>
        <DialogTitle />
        {JSX}
      </DialogContent>
    </Dialog>
  );
};
