import { Icon } from '@/core/icon';
import { cx } from '@/lib';
import { Outlet } from 'react-router-dom';

export const Layout = () => {
  return (
    <main
      className={cx(
        'bg-contain bg-top bg-no-repeat bg-apple bg-phases',
        'flex flex-col items-center justify-start',
        'h-screen w-full py-56',
      )}
    >
      <Icon name='OBJECT_LOGO_TEXT' className='w-[366px] h-[400px] min-w-[366px] min-h-[400px] mb-8' />
      <Outlet />
    </main>
  );
};
